import React from "react";
import ElectionResultsSection  from './results.jsx';
const Home = () => {
  return (
    <>
      {/* Meta Tags */}
      <meta charSet="utf-8" />
      <meta
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
        name="viewport"
      />
      {/* Author */}
      <meta name="author" content="Themes Industry" />
      {/* description */}
      <meta
        name="description"
        content="MegaOne is a highly creative, modern, visually stunning and Bootstrap responsive multipurpose studio and portfolio HTML5 template with 165+ ready home page demos."
      />
      {/* keywords */}
      <meta
        name="keywords"
        content="Creative, modern, clean, bootstrap responsive, html5, css3, portfolio, blog, studio, templates, multipurpose, one page, corporate, start-up, studio, branding, designer, freelancer, carousel, parallax, photography, studio, masonry, grid, faq"
      />
      {/* Page Title */}
      <title>Cree Health Board Chairperson Election 2024</title>
      {/* Favicon */}
      <link rel="icon" href="assets/vendor/img/favicon.ico" />
      {/* Bundle */}
      <link rel="stylesheet" href="assets/vendor/css/bundle.min.css" />
      {/* Plugin Css */}
      <link rel="stylesheet" href="assets/vendor/css/jquery.fancybox.min.css" />
      <link rel="stylesheet" href="assets/vendor/css/owl.carousel.min.css" />
      <link rel="stylesheet" href="assets/vendor/css/swiper.min.css" />
      <link rel="stylesheet" href="assets/vendor/css/wow.css" />
      {/* Slider Settings */}
      <link rel="stylesheet" href="assets/vendor/css/LineIcons.min.css" />
      <link rel="stylesheet" href="assets/election/css/settings.css" />
      {/* Style Sheet */}
      <link rel="stylesheet" href="assets/election/css/style.css" />
      {/*start loader*/}
      {/* Loader End */}
      {/*Navigation and Header Start*/}
      <header>
        <nav className="navbar navbar-expand-lg navbar-top-default fixed-top">
          <div className="container">
            <a className="navbar-brand scroll" href="#home">
            </a>
            {/*Nav Links*/}
          </div>
          {/* Container End */}
          <span />
          <span />
          <span />
          {/*Side Menu Button*/}
        </nav>
        {/* Navbar End */}
      </header>
      {/* Navigation and Header End */}
      {/*Banner start*/}
      <section className="banner-section position-relative" id="home">
        <div className="container">
          <div className="row banner-slider-row">
            <div className="col-12 col-lg-6 d-flex align-items-center text-center text-lg-left">
              <div className="banner-content">
                <h4 className="heading">
                  Cree Health Board Chairperson Election 2024
                </h4>
                <p className="text">
                  Welcome to the JBCCS election results for the Cree Board of Health
                  and Social Services of James Bay Chairperson Election. All results
                  contained on this page are unofficial results, the official
                  results can only be provided by the Returning Officer.
                </p>
                <div className="button-center">
                  <a
                    className="btn button btn-rounded white-btn btn-hvr-green"
                    href="https://listen.streamon.fm/jbccs"
                  >
                    Listen Live
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-end">
              <div className="img-area">
                <img
                  src="assets/election/img/CHB Logo Transparent.png"
                  alt="Cree Health Board Election 2024"
                  id="slider-inner-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="svg-div svg-anim">
          <svg
            data-depth="0.05"
            id="banner-main-svg"
            viewBox="0 0 180 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                id="grad1"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
                gradientTransform="rotate(120)"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: "rgb(23,110, 138)", stopOpacity: 1 }}
                />
                <stop
                  offset="40%"
                  style={{ stopColor: "rgb(23, 110, 138)", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
            <rect width={100} height={100} rx={8} fill="url(#grad1)" />
          </svg>
        </div>
        <div className="social-icons">
          <ul>
            <li>
              <a
                className="facebook-hovr"
                data-wow-delay="400ms"
                href="https://www.facebook.com/profile.php?id=61550122362900"
              >
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a
                className="instagram-hovr"
                data-wow-delay="400ms"
                href="https://www.youtube.com/@JBCCSStories-ro9do"
              >
                <i className="fab fa-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </section>
      {/*Banner End*/}
      {/* About Section Start */}
      <section className="about-section" id="about">
        <div className="container">
          <div className="row no-gutters">
            <div
              className="col-lg-12 col-md-12 col-sm-12 text-center text-section wow fadeInDown"
              data-wow-delay="400ms"
            >
              <h2 className="heading" style={{ wordBreak: "break-word" }}>
                Follow the Broadcast
              </h2>
              <p
                className="text"
                style={{ paddingLeft: "10%", paddingRight: "10%" }}
              >
                We will be broadcasting live from the JBCCS Studios on November
                12th, 2024 to bring you the election results from the Cree Board of
                Health and Social Services of James Bay Chairperson Election
              </p>
            </div>
          </div>
          <div className="row no-gutters">
            <div
              className="col-lg-12 col-md-12 col-sm-12 img-section wow fadeInUp"
              data-wow-delay="400ms"
              style={{ paddingTop: "2%" }}
            >
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://vimeo.com/event/4720660/embed"
                  frameBorder={0}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen=""
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                />
              </div>

            </div>
            {/* Container End */}
          </div>
        </div>
      </section>
      {/* About Section End*/}
      {/* Skills Section Start */}
      <section className="skills-section" id="skills">
        <div className="container">
          <div
            className="row row-1 no-gutters sec-heading text-center wow fadeInDown"
            data-wow-delay="400ms"
          >
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <h2 className="heading">
                CHB Chairperson <br />{" "}
                <span className="green-text">Election Results</span>
              </h2>
              <p className="text">
                As results are received from each community throughout the evening,
                we will be updating this
                <br />
                page with the latest vote counts. Results will be posted immediately
                after they are provided by
                <br />
                the staff from each polling station.
              </p>
            </div>
          </div>
          {/* Row-2 */}
          <section className="skills-section" id="skills" style={{ paddingTop: "0px" }}>
          <ElectionResultsSection electionId="0c63c6e9-785c-449d-907c-60a4e3e9f9d5" />
          </section>
        </div>
        {/* Container End */}
      </section>
      {/* Contact-Section Start */}
      {/* Contact-Section End */}
      {/* Footer Start */}
      <footer className="no-gutters">
        <div className="container">
          <div className="row no-gutters">
            <div
              className="col-lg-6 col-md-12 col-sm-12 social-icons wow fadeInDown"
              data-wow-delay="400ms"
            >
              <ul>
                <li>
                  <a
                    className="facebook-hover wow fadeInUp"
                    data-wow-delay="400ms"
                    href="https://www.facebook.com/profile.php?id=61550122362900."
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                    <a
                        className="youtube-hover wow fadeInUp"
                        data-wow-delay="400ms"
                        href="https://www.youtube.com/@JBCCSStories-ro9do"
                    >
                        <i className="fab fa-youtube" />
                    </a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <p className="text">
                © 2024 James Bay Cree Communication Society
              </p>
            </div>
          </div>
        </div>
        {/* Container End */}
      </footer>
      {/* Footer Ends */}
      {/* JavaScript */}
      {/* Plugin Js */}
      {/* custom script */}
    </>
  );
};

export default Home;
